import React, { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Select,
} from "@mui/material";
import ApiMethods from "../../ApiManager/ApiMethods";

const Card = ({
  status,
  appointment,
  rescheduleAppointment,
  checkedCard,
  isFull,
  timeSlot_id,
  date,
  time,
  updateStateReservation,
  free_bookings,
  isBooked,
  start,
  checkCard,
  freeAppointments,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleCardClick = () => {
    setOpenPopup(true);
  };

  const handleCancelBooking = async () => {
    try {
      const data = {
        client_id: localStorage.getItem("id"),
        timeSlot_id: appointment.id,
      };
      await ApiMethods.post("auth/delete_bookings", data);
      updateStateReservation();
    } catch (err) {
      console.log(err, "err");
    }

    console.log("Cancel the booking");
    setOpenPopup(false);
  };

  const handleRescheduleBooking = () => {
    rescheduleAppointment(appointment);
    setOpenPopup(false);
  };

  return (
    <>
      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        classes={{ paper: "custom-dialog-paper" }}
      >
        <DialogTitle className="custom-dialog-title">
          Izaberi akciju
        </DialogTitle>
        <DialogContent className="custom-dialog-content">
          <Typography variant="body1">
            Vreme:{date}-{time}
          </Typography>
          <Typography variant="body1">
            Sta zelite da uradite sa svojim terminom?
          </Typography>
        </DialogContent>
        <DialogActions className="custom-dialog-actions">
          <Button
            onClick={handleCancelBooking}
            color="primary"
            className={`custom-dialog-button custom-cancel-button`}
          >
            Otkazi termin
          </Button>
          <Button
            onClick={handleRescheduleBooking}
            color="primary"
            className={`custom-dialog-button custom-reschedule-button`}
          >
            Izmeni termin
          </Button>
        </DialogActions>
      </Dialog>
      <div
        // onClick={() =>
        //   !isBooked && !isMyAppointment && timeSlot.id
        //     ? toggleChecked(timeKey, day, time, date, timeSlot.id)
        //     : null
        // }
        onClick={() =>
          appointment.status == "available"
            ? checkCard(appointment.start, appointment.id, appointment)
            : appointment.status == "booked"
            ? handleCardClick()
            : null
        }
        className="cell-status"
        style={{
          fontSize: "10px",
          textTransform: "uppercase",
          fontWeight: "bold",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s, transform 0.2s",
          cursor: "pointer",
          color: "#fff",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            opacity: isFull && !isBooked ? "0.4" : "1",
            backgroundColor: appointment.backgroundColor,
          }}
          className={
            appointment.checked ? "parent1-selected-card" : "parent1-card"
          }
        >
          {appointment.status === "booked" ? (
            <span className="appointment-title">{appointment.title}</span>
          ) : null}

          {appointment.status == "available" ? (
            <div className={"check-icon"}>
              <DoneIcon
                style={{ color: appointment.checked ? "orange" : "white" }}
                sx={{ fontSize: appointment.checked ? 20 : 25 }}
              />
              <div className="text-status1">
                {[...Array(appointment.free_bookings)].map((_, index) => (
                  <PersonIcon
                    key={index}
                    style={{ color: "black" }}
                    sx={{ fontSize: checkedCard ? 6 : 7 }}
                  />
                ))}
              </div>
            </div>
          ) : appointment.status == "full" ? (
            <div className="text-status1-full">
              <PersonOffIcon style={{ color: "black" }} sx={{ fontSize: 7 }} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Card;
