import React, { useState, useRef, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import ApiMethods from "../../../ApiManager/ApiMethods";
import SidebarComponent from "./SideBar/sidebar";
import { IconButton, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Card from "../../components/card";
import CheckIcon from "@mui/icons-material/Check";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationManager from "../../components/notification";
import { ScheduleModal } from "./components/ScheduleModal";
import { SchedulePopover } from "./components/SchedulePopover";
import AlertComponent from "../../components/alert";
import { Popup } from "../../components/popup";

const customNavigationPickerProps = {
  leftArrowIcon: (
    <IconButton>
      <CalendarMonthIcon />
    </IconButton>
  ),
  rightArrowIcon: (
    <IconButton>
      <CalendarTodayIcon />
    </IconButton>
  ),
};

export const appointmentStatuses = {
  FULL: "full",
  AVAILABLE: "available",
  BOOKED: "booked",
};

export const appointmentStatusesProps = {
  FULL: {
    backgroundColor: "red",
    title: "Popunjeno",
  },
  AVAILABLE: {
    backgroundColor: "#98C3F2",
    title: "",
  },
  BOOKED: {
    backgroundColor: "#357a38",
    title: "Rezervisano",
  },
};

const BookingPage = ({ checkUser }) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const schedulerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [alert, setAlert] = useState();
  const [updateReservation, setUpdateReservation] = useState(false);

  const [selectedCards, setSelectedCards] = useState(0);

  const [appointments, setAppointments] = useState([]);

  const [selectedCardByDay, setSelectedCardByDay] = useState({});

  const [rescheduledAppointment, setReschedulingAppointment] = useState([]);
  const [appointmentToReschedule, setAppointmentToReschedule] = useState([]);

  const formattedStart = moment().startOf("week").format("DD");
  const formattedMonth = moment().startOf("week").format("MMMM");
  const formattedEnd = moment().endOf("week").format("DD");

  const selectedAppointments = Object.fromEntries(
    Object.entries(appointments).filter(
      ([key, appointment]) => appointment.checked
    )
  );

  const checkAlert = (validCredentials) => {
    console.log("usao sadas asdas");
    setAlert(validCredentials);
    setTimeout(() => {
      setAlert(undefined);
    }, 800);
  };

  const logOut = () => {
    localStorage.clear();
    checkUser();
  };

  const handleFetchBookings = async () => {
    const data = {
      client_id: localStorage.getItem("id"),
    };

    const appointmentsResponse = await ApiMethods.get("auth/bookings");

    const myAppointmentsResponse = await ApiMethods.post(
      "auth/client_bookings",
      data
    );

    const tempAppointments = appointmentsResponse.map((appointment) => {
      const tempAppointment = {
        id: appointment.id,
        start: moment(appointment.start).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(appointment.end).format("YYYY-MM-DD HH:mm:ss"),
        max_bookings: appointment.max_bookings,
        used_bookings: appointment.used_bookings,
        free_bookings: appointment.free_bookings,
        status: null,
        checked: false,
      };

      const isMyAppointment =
        myAppointmentsResponse.findIndex((ma) => ma.id === appointment.id) > -1;

      if (isMyAppointment) {
        tempAppointment.status = appointmentStatuses.BOOKED;
        tempAppointment.backgroundColor =
          appointmentStatusesProps.BOOKED.backgroundColor;
        tempAppointment.title = appointmentStatusesProps.BOOKED.title;
        return tempAppointment;
      }

      if (tempAppointment.free_bookings === 0) {
        tempAppointment.status = appointmentStatuses.FULL;
        tempAppointment.backgroundColor =
          appointmentStatusesProps.FULL.backgroundColor;
        tempAppointment.title = appointmentStatusesProps.FULL.title;
        return tempAppointment;
      }

      if (tempAppointment.free_bookings > 0 && !isMyAppointment) {
        tempAppointment.status = appointmentStatuses.AVAILABLE;
        tempAppointment.backgroundColor =
          appointmentStatusesProps.AVAILABLE.backgroundColor;
        tempAppointment.title = appointmentStatusesProps.AVAILABLE.title;
        return tempAppointment;
      }

      return tempAppointment;
    });

    const appointmentsObject = tempAppointments.reduce((acc, appointment) => {
      acc[appointment.start] = appointment;
      return acc;
    }, {});

    setAppointments(appointmentsObject);

    setInitialized(true);
  };

  const checkCard = (key, id, appointment) => {
    const date = moment(key).format("YYYY-MM-DD");
    if (Object.keys(rescheduledAppointment).length) {
      console.log("ovo je appointment", appointment);
      setAppointmentToReschedule(appointment);
      setOpen(true);
    } else {
      Object.keys(appointments).forEach((key) => {
        if (key.startsWith(date) && appointments[key].id != id) {
          appointments[key].checked = false;
        }
      });
      appointments[key].checked = !appointments[key].checked;
      setAppointments({ ...appointments });
    }
  };
  useEffect(() => {
    console.log(selectedAppointments);
  }, [appointments]);

  const updateStateReservation = async () => {
    setInitialized(false);
    await handleFetchBookings();
  };

  useEffect(() => {
    handleFetchBookings();
  }, []);

  useEffect(() => {
    if (schedulerRef.current && schedulerRef.current.scheduler) {
      schedulerRef.current.scheduler.handleState(selectedDate, "selectedDate");
    }
  }, [selectedDate]);

  const rescheduleAppointment = async (appointment) => {
    setReschedulingAppointment(appointment);
    console.log(appointment);
  };

  const handleNavigate = (direction) => {
    let newDate;
    if (direction === "next") {
      newDate = moment(selectedDate).add(1, "week").toDate();
    } else if (direction === "prev") {
      newDate = moment(selectedDate).subtract(1, "week").toDate();
    }
    setSelectedDate(newDate);
    if (schedulerRef.current) {
      schedulerRef.current.scheduler.selectedDate = newDate;
    }
  };

  const cellRender = (props) => {
    if (!initialized) {
      return null;
    }
    const { start, day, end } = props;
    const formatedStart = moment(start).format("YYYY-MM-DD HH:mm:ss");
    const currentAppointment = appointments[formatedStart];
    if (!currentAppointment) {
      return null;
    }
    const timeKey = start.getTime();
    const dayKey = day.toLocaleDateString();

    let timeSlot = -1;

    const startHour = `${String(start.getHours()).padStart(2, "0")}:${String(
      start.getMinutes()
    ).padStart(2, "0")}`;
    const endHour = `${String(end.getHours()).padStart(2, "0")}:${String(
      end.getMinutes()
    ).padStart(2, "0")}`;

    const date = day.toLocaleDateString();
    const time = `${startHour} - ${endHour}`;

    if (["14:00", "13:00", "12:00"].includes(startHour)) {
      return null;
    }

    return (
      <Card
        rescheduleAppointment={rescheduleAppointment}
        freeAppointments={appointments}
        checkCard={checkCard}
        appointment={currentAppointment}
        checkedCard={selectedCardByDay[dayKey] === timeKey}
        date={date}
        time={time}
        updateStateReservation={updateStateReservation}
        updateReservation={updateReservation}
      />
    );
  };

  return (
    <div className="booking-page-container">
      {isModalVisible && (
        <ScheduleModal
          checkAlert={checkAlert}
          checkCard={checkCard}
          updateStateReservation={updateStateReservation}
          appointments={selectedAppointments}
          closePopup={() => setIsModalVisible(false)}
        />
      )}
      <SidebarComponent isModalVisible={isModalVisible} logOut={logOut} />

      <div className="booking-page" style={{ zIndex: "0" }}>
        {/* <AlertComponent alert={alert} /> */}
        <div className="parent-nav">
          <div className="nav-calendar-wrapper">
            <CalendarMonthIcon className="calendar-icon" />
            <div className="nav-calendar">
              <IconButton
                onClick={() => handleNavigate("prev")}
                className="icon-button"
              >
                <ArrowBackIosIcon fontSize="small" />
              </IconButton>
              <Typography variant="h6" className="date-text">
                {`${formattedStart}-${formattedEnd} ${formattedMonth}`}
              </Typography>
              <IconButton
                onClick={() => handleNavigate("next")}
                className="icon-button"
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
        <NotificationManager />
        {open ? (
          <Popup
            text="Hello theree!"
            newAppointment={rescheduledAppointment}
            oldAppointment={appointmentToReschedule}
            updateStateReservation={updateStateReservation}
            closePopup={() => setOpen(false)}
          />
        ) : null}

        <Scheduler
          view="week"
          ref={schedulerRef}
          navigation={false}
          customNavigationPickerProps={customNavigationPickerProps}
          // navigationPickerProps={customNavigationPickerProps} // Pass custom picker props
          week={{
            // startHour: !isMobile ? 8 : shift ? 15 : 8,
            // endHour: !isMobile ? 22 : shift ? 22 : 13,
            startHour: 8,
            endHour: 22,
            weekStartOn: 1,
            weekDays: [0, 1, 2, 3, 4, 5],
            cellRenderer: cellRender,
            excludeTimes: [
              { start: "12:00", end: "13:00" },
              { start: "13:00", end: "14:00" },
              { start: "14:00", end: "15:00" },
            ], // Exclude times from being rendered in the view
          }}
          selectedDate={selectedDate}
          disableViewNavigator={true}
          hourFormat="24"
        />

        <SchedulePopover
          selectedCards={Object.keys(selectedAppointments).length}
          setShowModal={setIsModalVisible}
        />
      </div>
    </div>
  );
};

export default BookingPage;
