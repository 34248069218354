import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiMethods from "../../../../ApiManager/ApiMethods";

const VerifyPage = ({ loginVerification }) => {
  const { guid } = useParams(); // Extract the GUID from the URL
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [counter, setCounter] = useState(30);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    getPhoneNumber();
  }, []);

  const getPhoneNumber = async (e) => {
    try {
      const response = await ApiMethods.get(`client/phone/${guid}`);
      setPhoneNumber(response.phone);
    } catch (err) {}
  };
  const resendCode = async () => {
    try {
      setCounter(30);

      const data = {
        phone: phoneNumber,
      };
      const response = await ApiMethods.post(
        "auth/client/register_phone",
        data
      );
    } catch (err) {
      console.log("Err", err);
    }
  };

  // Submit the code for verification
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const data = {
        guid: guid,
        verification_code: code,
      };

      const response = await ApiMethods.post("auth/verify_number", data);
      await loginVerification();
      navigate("/");

      //   const result = await response.json();

      //   if (result.success) {
      //     setIsVerified(true);
      //     // Redirect to login or home page after successful verification
      //     navigate("/login");
      //   } else {
      //     setError("Invalid verification code");
      //   }
    } catch (err) {
      console.error(err); // Log the error for debugging
      setError("Error verifying code");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Verifying...</p>;
  }

  return (
    <div>
      <div className="phone-verification">
        <h2 className="verification-title">Enter Verification Code</h2>
        {counter > 0 ? (
          <div className="timer">Time left: {counter}</div>
        ) : (
          <div className="expired">Verification code has expired</div>
        )}

        <form onSubmit={handleSubmit} className="verification-form">
          <input
            type="text"
            value={code}
            onChange={handleCodeChange}
            placeholder="Enter code"
            required
            className="verification-input"
          />
          <button type="submit" className="verification-button">
            Verify
          </button>
          {counter == 0 ? (
            <button
              type="submit"
              onClick={resendCode}
              className="verification-button"
            >
              Resend code
            </button>
          ) : (
            <div></div>
          )}
        </form>
      </div>

      {error && <p>{error}</p>}

      {isVerified && (
        <p>
          Verification successful! You will be redirected to the login page.
        </p>
      )}
    </div>
  );
};

export default VerifyPage;
