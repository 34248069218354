import React, { useState,useEffect } from "react";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

export const ScheduleModal = ({appointments = [], closePopup,updateStateReservation,checkCard,checkAlert}) => {
    const [selectedAppointments, setSelectedAppointments] = useState(appointments);
      const addBooking = async () => {
        try {
          const timeSlots = Object.keys(selectedAppointments).map((appointment) => ({
            client_id: localStorage.getItem("id"),
            status: "book",
            timeSlot_id:selectedAppointments[appointment].id,
          }));
          
    
          const data = {
            timeSlots: timeSlots,
          };
          const response = await ApiMethods.post("auth/add_booking", data);
          console.log("ovo je response",response)
          updateStateReservation();
          await checkAlert(true);
          closePopup();
        } catch (err) {
          await checkAlert(false);
          closePopup();    
        }
      };
      const renderAppointments=()=>{
       return  Object.keys(selectedAppointments).map((appointment) => (
        
        <div key={appointment} className="appointment-card">
        <div className="appointment-card-header">
          <strong>Detalji zakazivanja</strong>
        </div>
        <div className="appointment-info">
          <div className="appointment-service">
            <strong>Trening:</strong>
          </div>
          <div className="appointment-time">
            <strong>Datum:</strong> {moment(selectedAppointments[appointment].start).format("YYYY-MM-DD")} <br />
            <strong>Vreme:</strong> {moment(selectedAppointments[appointment].start).format("h:mm")}-{ moment(selectedAppointments[appointment].end).format("h:mm")}
          </div>
        </div>
        <div
          onClick={() =>
          checkCard(appointment,selectedAppointments[appointment].id)
          }
          className="close-btn"
        >
          <CloseIcon fontSize="small" />
        </div>
      </div>   
         
        ))
      }

    return (
        <div className="fullscreen-overlay">
            <div className="appointment-popup">
              <h2 className="popup-title">Pregled Termina</h2>
              <div className="appointment-details">
              {renderAppointments()}
              </div>
              <div className="button-container">
                <button onClick={closePopup} className="button cancel-btn">
                  Cancel
                </button>
                <button onClick={addBooking} className="button confirm-btn">
                  Confirm
                </button>
              </div>
            </div>
          </div>
    )
}