import React, { useEffect } from "react";

const ConfirmationPage = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get("uid");

    fetch(`https://app-dev.x.tgn.mk/api/auth/confirmation/${uid}`, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        console.error("Error fetching confirmation:", error);
      });
  }, []);

  return (
    <div className="main-div">
      <p className="confirmed-p">
        Congratulations!
        <br />
        Your email has been confirmed. You can now log in to the application.
      </p>
    </div>
  );
};

export default ConfirmationPage;
