import "./css/bootstrap.min.css";
import "./css/tooplate-infinite-loop.css";
import Login from "./app/Screens/public/Login";
import Public from "./app/Screens/public/index";
import Secure from "./app/Screens/secure/index";
import { FadeLoader } from "react-spinners";
import React from "react";

import { useState, useEffect, Fragment } from "react";
// import { getFCMToken, onMessageListener } from "./firebase";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VerifyPage from "./app/Screens/public/VerifyNumber";
// import { setupNotifications } from "./firebase";
import {
  toastNotification,
  sendNativeNotification,
} from "./notificationHelper";
import useVisibilityChange from "./useVisibilityChange";
import NotificationManager from "./app/components/notification";
import MyBookings from "./app/Screens/secure/MyBookings/bookings";
import ConfirmationPage from "./app/Screens/public/ConfirmEmail";
import ResetPassword from "./app/Screens/public/ResetPassword";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [message, setMessage] = useState(null);

  const [path, setPath] = useState("");
  const isForeground = useVisibilityChange();
  // useEffect(() => {
  //   console.log("isForeground:", isForeground);
  //   console.log("message:", message);

  //   if (isForeground && message) {
  //     console.log("Received message in foreground:", message);

  //     // When the app is in the foreground and a message is received
  //     setNotification({
  //       title: message.title,
  //       body: message.body,
  //     });
  //   } else if (!isForeground && message) {
  //     console.log("Received message in background:", message);

  //     // If the app is in the background and a message is received
  //     if (Notification.permission === "granted") {
  //       new Notification(message.title, {
  //         body: message.body,
  //       });
  //     }
  //   }
  // }, []);
  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //     } else {
  //       console.log("Notification permission denied.");
  //     }
  //   });
  // }, []);

  const loginVerification = () => {
    console.log("Login verification called");
    setAuthenticated(true);
    setPath("/");
    console.log(authenticated);
  };

  const checkUser = () => {
    const token = localStorage.getItem("token");
    // setAuthenticated(true);
    // setLoading(false);
    // return;

    // Check if the token exists
    if (token) {
      setAuthenticated(true);
      setLoading(false);
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (authenticated) {
      console.log("User is now authenticated:", authenticated);
    }
  }, [authenticated]);

  const handleLoginVerification = () => {
    console.log("Login verification triggered");
  };

  // Call checkUser on component mount or whenever necessary
  useEffect(() => {
    checkUser(); // Check the user's authentication status when the component mounts
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="spinner-loader">
          <FadeLoader />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route
              path="/verify/:guid"
              element={<VerifyPage loginVerification={loginVerification} />}
            />
            <Route path="/confirmation" element={<ConfirmationPage />} />
            <Route path="/forgotpassword/:guid" element={<ResetPassword />} />

            {!authenticated ? (
              <Route
                path="/"
                element={<Public loginVerification={loginVerification} />}
              />
            ) : (
              <>
                <Route path="/" element={<Secure checkUser={checkUser} />} />
                <Route path="/my-bookings" element={<MyBookings />} />
              </>
            )}
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
