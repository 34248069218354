import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ApiMethods from "../../../../ApiManager/ApiMethods";

const ResetPassword = () => {
  const [uid, setUid] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { guid } = useParams(); // Extract the GUID from the URL

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const data = {
        guid: guid,
        password: password,
      };
      const response = await ApiMethods.post("auth/forgot-password", data);
      console.log("response", response);
      alert(response.message);
      window.location.reload();
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="reset-password-container">
      {isFormVisible ? (
        <div id="forgotPasswordForm">
          <h2>Reset Password</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              placeholder="Enter new password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="8"
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              minLength="8"
            />
            <input type="submit" value="Reset Password" />
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default ResetPassword;
