import { useState, Fragment } from "react";
import Button from "../../../components/button";
import Input from "../../../components/input";
import validator from "validator";
import axios from "axios";
import React, { useImperativeHandle, forwardRef } from "react";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import SendNotification from "../../../components/pushnotification";
import { requestForToken, onMessageListener } from "../../../../firebase";

const Login = ({
  handleToggle,
  checkAlert,
  handleForgotPassword,
  loginVerification,
  handleToggleRegisterNumber,
}) => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [platform, setPlatform] = useState("");

  const onLogin = async (e) => {
    // if (e) e.preventDefault();
    await requestForToken();

    // Reset error messages
    setEmailError("");
    setPasswordError("");

    // Validate email format
    if (!validator.isEmail(email)) {
      setEmailError("Please insert a valid email format!");
      return;
    }

    // Validate password
    if (password === "") {
      setPasswordError("Please enter a password");
      return;
    }

    if (password.length < 6) {
      setPasswordError("The password must be 8 characters or longer");
      return;
    }

    try {
      const data = {
        email,
        password,
        token: localStorage.getItem("fcm_token"),
      };

      const response = await ApiMethods.post("auth/client/login", data);

      if (response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("id", response.id);
        localStorage.setItem("firstName", response.first_name);
        localStorage.setItem("lastName", response.last_name);
        localStorage.setItem("guid", response.guid);

        setToken(response.token); // Set token in state if needed
        await checkAlert(true);
        loginVerification();
        console.log("Login successful", response);
      } else {
        throw new Error("Token not found in response");
      }
    } catch (error) {
      await checkAlert(false);
      setEmailError("Invalid credentials");
      setPasswordError("Invalid credentials");
      console.error("Error during login:", error);
    }
  };

  const handleKeyPress = (event) => {
    console.log("fwefweef");
    // Trigger onLogin when Enter key is pressed
    if (event.key === "Enter") {
      onLogin(event);
    }
  };

  const validateEmail = (e) => {
    setEmail(e.target.value);
  };

  const validatePassword = (e) => {
    setPassword(e.target.value);
  };

  const onkeydown = (event) => {
    if (event.key === "Enter") {
      onLogin();
    }
  };

  return (
    <div onKeyDown={onkeydown}>
      <div>
        <div tabIndex={0} className="uf_login_text">
          Log in
        </div>
        <Input
          label={"Email"}
          error={emailError}
          placeholder={"Email"}
          id={"email"}
          type={"email"}
          nameInput={"email"}
          onChange={validateEmail}
        />
        <Input
          label={"Password"}
          error={passwordError}
          placeholder={"Password"}
          id={"password1"}
          type={"password"}
          nameInput={"password1"}
          value={password}
          onChange={validatePassword}
          onKeyDown={handleKeyPress} // Trigger login on Enter
        />
        <div>
          <div className="forgot-password">
            <span
              className="register-phone"
              style={{ fontSize: "17px" }}
              onClick={handleToggleRegisterNumber}
            >
              Sign in with phone
            </span>
            <span
              className="forgot-span"
              style={{ fontSize: "17px" }}
              onClick={handleForgotPassword}
            >
              Forgot password?
            </span>
          </div>
          <Button
            className={"btn-login-forgot"}
            name={"Log in"}
            onClick={onLogin}
            type="button"
          />
          <div>
            <span style={{ fontSize: "17px" }}>
              Don't have an account?{" "}
              <span
                onClick={handleToggle}
                style={{ cursor: "pointer", fontWeight: "bold" }}
              >
                Register
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
