import { useState, Fragment } from "react";
import React from "react";
import axios from "axios";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Alert from "@mui/material/Alert";
import validator from "validator";
import ApiMethods from "../../../../ApiManager/ApiMethods";

const RegisterNumber = ({ handleToggle }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = useState();

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleRegisterWithPhone = async () => {
    setLoading(true);
    setMessage(null);
    setError(null);

    // if (validator.isEmail(email)) {
    //   // setEmailError("Valid Email :)");
    // } else {
    //   setError("Please insert right email format!");
    //   setLoading(false);
    //   return;
    // }

    try {
      const data = {
        phone: phoneNumber,
      };
      console.log(data);
      const response = await ApiMethods.post(
        "auth/client/register_phone",
        data
      );
      // console.log(response, "ovo je response");

      setMessage("Register  link has been sent to your phone number.");
    } catch (error) {
      setError(error, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="forgot-password-div">
        <p className="par-pass">Register with a number</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Input
          label="Phone"
          placeholder={"+381123456789"}
          type="phone"
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </div>
      <Button
        className={"btn-login-forgot"}
        name={loading ? "Sending..." : "Confirm"}
        onClick={handleRegisterWithPhone}
        disabled={loading || !phoneNumber}
        type="button"
      />
      <div style={{ fontSize: "17px" }} className="login-part">
        <span>
          Already have an account?{" "}
          <span
            onClick={handleToggle}
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            Login
          </span>
        </span>
      </div>
      {/* <Button
        className="btn-back-to-login"
        name="Back to Login"
        onClick={handleToggle}
        type="button"
      /> */}
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </Fragment>
  );
};

export default RegisterNumber;
