import React from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ApiMethods from "../../ApiManager/ApiMethods";

export const Popup = ({
  closePopup,
  onConfirm,
  oldAppointment,
  newAppointment,
  updateStateReservation,
}) => {
  const handleConfirmRescedule = async () => {
    try {
      const data = {
        timeslot_old_id: newAppointment.id,
        timeslot_new_id: oldAppointment.id,
        client_id: localStorage.getItem("id"),
      };
      const response = await ApiMethods.post("auth/reschedule", data);
      closePopup();
      updateStateReservation();
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-body">
        <div className="popup-content">
          <div onClick={closePopup} className="close-btn reschedule-btn">
            <CloseIcon fontSize="small" />
          </div>
          <p>Da li želite da zamenite trenutni termin sa novim terminom?</p>

          <div className="appointment-details">
            <div className="old-appointment">
              <span>Stari Termin</span>

              <p>
                <strong>
                  {moment(oldAppointment.start).format("YYYY-MM-DD")} -{" "}
                  {moment(oldAppointment.start).format("h:mm A")} do{" "}
                  {moment(oldAppointment.end).format("h:mm A")}
                </strong>
              </p>
            </div>

            <div className="new-appointment">
              <span>Novi termin</span>
              <p>
                <strong>
                  {moment(newAppointment.start).format("YYYY-MM-DD")} -{" "}
                  {moment(newAppointment.start).format("h:mm A")} do{" "}
                  {moment(newAppointment.end).format("h:mm A")}
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <button className="popup-action-button cancel" onClick={closePopup}>
            Otkazi
          </button>
          <button
            className="popup-action-button confirm"
            onClick={handleConfirmRescedule}
          >
            Potvrdi
          </button>
        </div>
      </div>
    </div>
  );
};
