import axios from "axios";

const BASE_URL = "https://app-dev.x.tgn.mk/api/";
// const BASE_URL = "http://localhost:8000/api/";

class ApiMethods {
  static async apiRequest(method, url, data = {}, token = null) {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await axios({
        method,
        url: `${BASE_URL}${url}`,
        data: method !== "GET" ? data : null,
        headers,
      });

      if (response.status == 200) {
        return response.data;
      }
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }

  static get(url, token = null) {
    return this.apiRequest("GET", url, {}, token);
  }

  static post(url, data, token = null) {
    return this.apiRequest("POST", url, data, token);
  }

  static put(url, data, token = null) {
    return this.apiRequest("PUT", url, data, token);
  }

  static delete(url, token = null) {
    return this.apiRequest("DELETE", url, {}, token);
  }
}

export default ApiMethods;
