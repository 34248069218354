// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyDOg-x942QO_VNXeAOQDTVruYPrjTr4qsA",
  authDomain: "hit-fit-5eac2.firebaseapp.com",
  projectId: "hit-fit-5eac2",
  storageBucket: "hit-fit-5eac2.appspot.com", // Fix typo: `.app` should be `.com`
  messagingSenderId: "268816991166",
  appId: "1:268816991166:web:67ba1ccabff3855e1fefef",
  measurementId: "G-ZQ41JCJ7VN",
};

const firebase = initializeApp(firebaseConfig);
console.log(firebase);

const messaging = getMessaging();

// let userUid = null;

// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     userUid = user.uid; // Get the UID
//     console.log("User UID: ", userUid);
//   } else {
//     console.log("No user is signed in.");
//   }
// });

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: `BJEU_5R_zbMc3wzCzOn66I_hNbdU_UOiZyoat8Ps6a5SpgXQay77zGqDce6NvHrMIMJAPrK0oRO9rBFbuMVEDjc`,
    });

    if (currentToken) {
      localStorage.setItem("fcm_token", currentToken);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.error("An error occurred while retrieving token: ", err);
  }
};

navigator.serviceWorker
  .register("/firebase-messaging-sw.js")
  .then((registration) => {
    localStorage.setItem(
      "firebase",
      JSON.stringify({ scope: registration.scope })
    );
    console.log("firebase-messaging registered:", registration);
  })
  .catch((err) => {
    localStorage.setItem("firebase", "Firebase not set");
    console.error("firebase-messaging registration failed:", err);
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
