import ApiMethods from "../../../../ApiManager/ApiMethods";
import React, { useState, useEffect } from "react";
import { format } from "date-fns"; // To format dates
import { EventNote, AccessAlarm, People, ArrowBack } from "@mui/icons-material"; // Import ArrowBack icon
import { Link } from "react-router-dom"; // Import Link for navigation

const MyBookings = () => {
  const [myBookingsList, setMyBookings] = useState([]);

  const formatTime = (date) => {
    return format(date, "hh:mm a");
  };

  useEffect(() => {
    myBookings();
  }, []);

  useEffect(() => {
    console.log("Updated myBookingsList:", myBookingsList);
  }, [myBookingsList]);

  const myBookings = async () => {
    const clientId = localStorage.getItem("id");
    if (!clientId) {
      console.error("Client ID not found in localStorage.");
      return;
    }

    const data = { client_id: clientId };
    try {
      const response = await ApiMethods.post("auth/client_bookings", data);

      console.log("API Response:", response);
      setMyBookings(response);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  return (
    <div className="my-bookings-container">
      {/* Link with ArrowBack icon for navigation */}
      <div className="back-to-home">
        <Link to="/" className="back-link">
          <ArrowBack sx={{ color: "black" }} fontSize="large" />
        </Link>

        <h1 className="page-title">Istorija zakazivanja</h1>
      </div>

      {myBookingsList.length > 0 ? (
        <div className="bookings-list">
          {myBookingsList.map((booking) => {
            const startDate = new Date(booking.start); // Convert to Date object
            const endDate = new Date(booking.end); // Convert to Date object

            // Format the date and times
            const formattedDate = format(startDate, "MMMM dd, yyyy");
            const formattedStart = formatTime(startDate); // Format start time
            const formattedEnd = formatTime(endDate); // Format end time

            return (
              <div key={booking.id} className="booking-card">
                <div className="booking-header">
                  <EventNote fontSize="large" />
                  <h3 className="booking-date">{formattedDate}</h3>
                </div>
                <p className="booking-time">
                  <strong>Vreme:</strong> {formattedStart} - {formattedEnd}
                </p>
                <div className="booking-footer">
                  <People fontSize="small" />
                  <span className="booking-status">Trening</span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No bookings found.</p>
      )}
    </div>
  );
};

export default MyBookings;
