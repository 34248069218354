import { useState, Fragment } from "react";
import React from "react";
import Button from "../../../components/button";
import validator from "validator";
import Input from "../../../components/input";
import axios from "axios";
import ApiMethods from "../../../../ApiManager/ApiMethods";

const Register = ({ handleToggle, checkAlert }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");

  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPhone, setPhoneError] = useState("");

  const validatePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const validateFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const validateLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };
  const validateConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);

    // Example password validation criteria
  };
  const validatePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);

    // Example password validation criteria
  };

  const onRegister = async (e) => {
    setEmailError("");
    setPasswordError("");
    setFirstNameError("");
    setLastNameError("");
    console.log(firstName);
    console.log("nestoo");
    console.log(password, "pass");

    if (firstName === "") {
      console.log("usao sam ovde");
      setFirstNameError("Please enter first name");
      return;
    }

    if (lastName === "") {
      setLastNameError("Please enter last name");
      return;
    }
    // if (phone === "") {
    //   setPhoneError("Please enter phone number");
    //   return;
    // }

    if (!validator.isEmail(email)) {
      setEmailError("Enter a valid email!");
      return;
    }

    if (password === "") {
      setPasswordError("Please enter password");
      return;
    }

    if (confirmPassword === "") {
      setConfirmPassword("Please enter password");
      return;
    }
    if (confirmPassword != password) {
      setConfirmPassword("Passwords do not match.");
      setPasswordError("Passwords do not match.");
      return;
    }

    if (password.length < 6) {
      setPasswordError("The password must be 8 characters or longer");
      return;
    }

    console.log("All validations passed");
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        // phone: phone,
      };
      const response = await ApiMethods.post("auth/client/register", data);

      console.log("Registration successful", response.data);
      await checkAlert(true);
      handleToggle();

      // Handle successful registration (e.g., redirect, show a success message, etc.)
    } catch (error) {
      await checkAlert(false);

      setEmailError("User already exist");
      console.error("Error during registration:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const onkeydown = (event) => {
    if (event.key === "Enter") {
      onRegister();
    }
  };

  // const validatePhoneNumber = (e) => {
  //   const value = e.target.value;
  //   setPhone(value);

  //   // Check if the phone number starts with +381
  //   if (!value.startsWith("+381")) {
  //     setError("Phone number must start with +381");
  //   } else {
  //     setError(""); // Clear error if valid
  //   }
  // };

  return (
    <Fragment>
      <div onKeyDown={onkeydown}>
        <div className="uf_login_text">Register</div>
        <Input
          label={"First name"}
          placeholder={"First name"}
          id={"firstName"}
          type={"text"}
          nameInput={"firstName"}
          error={firstNameError}
          onChange={validateFirstName}
        />
        <Input
          label={"Last name"}
          error={lastNameError}
          placeholder={"Last name"}
          id={"lastName"}
          type={"text"}
          nameInput={"lastName"}
          onChange={validateLastName}
        />
        {/* <Input
          label={"Phone number"}
          error={confirmPhone}
          placeholder={"+381123456789"}
          id={"phone"}
          type={"phone"}
          nameInput={"phone"}
          onChange={validatePhone}
        /> */}
        <Input
          label={"Email"}
          error={emailError}
          placeholder={"Email"}
          id={"email1"}
          type={"email"}
          nameInput={"email1"}
          onChange={validateEmail}
        />
        <Input
          label={"Password"}
          error={passwordError}
          placeholder={"Password"}
          id={"password1"}
          type={"password"}
          nameInput={"password1"}
          value={password}
          onChange={validatePassword}
        />
        <Input
          label={"Confirm password"}
          error={passwordError}
          placeholder={"Confirm password"}
          id={"confirm"}
          type={"password"}
          nameInput={"confirm"}
          value={password}
          onChange={validateConfirmPassword}
        />
        <Button type="submit" name={"Register"} onClick={onRegister} />
        <div style={{ fontSize: "17px" }} className="login-part">
          <span>
            Already have an account?{" "}
            <span
              onClick={handleToggle}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            >
              Login
            </span>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Register;
